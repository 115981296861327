import React from "react";
import Layout from "../components/layout/Layout";
import '../assets/styles/sass/Policy/PrivacyPolicy.sass'

export default function UserAgreement() {
  return (
    <Layout>
        <div className="mainPolicy">
            <h1>İstifadəçi Razılaşması</h1>
            <p>1.1. Bu İstifadəçi Razılaşması (“Razılaşma”) Azərbaycan Respublikası Vergilər Nazirliyi Milli Gəlirlər Departamenti Hüquqi Şəxslərin Dövlət Qeydiyyatı idarəsi tərəfindən 1007824921 qeydiyyat nömrəsi ilə Azərbaycanda yaradılmış və hüquqi ünvanı Bakı şəhəri, Yasamal rayonu, Murtuz Muxtarov küçəsi, ev.185B-105 ünvanında yerləşən, özəl şirkət olan “E-İDEA” MMC və Azərbaycan Respublikası Vəkillər Kollegiyası tərəfindən idarə olunan Doktap elektron hüquqi yardım mobil tətbiqində tam fəaliyyət qabiliyyətinə malik fiziki şəxslərin Vəkil(lər) tərəfindən göstəriləcək xidmətlərdən (“Xidmətlər”) istifadəsi, xidmətlərə keçid alması və ümumi qaydaları müəyyən edir.</p>
            <p>1.2. Bu Razılaşmanı təsdiq etməklə siz Doktap elektron hüquqi yardım mobil tətbiqi vasitəsilə göstəriləcək Xidmətlərə keçid aldığınızı və onlardan istifadəni bəyan etmiş olursunuz. Razılaşmanı təsdiq etmədiyiniz halda, Xidmətlərə keçid ala və onlardan istifadə edə bilməniz mümkün olmayacaqdır.</p>
            <p>1.3. Doktap elektron hüquqi yardım mobil tətbiqi istənilən zaman istənilən səbəbdən bu Razılaşma şərtlərini və ya sizinlə bağlı hər hansı Xidmətləri dərhal dayandıra və ya ümumilikdə Xidmətlər və ya onların hər hansı hissəsini təklif etməkdən imtina edə və ya onlara çıxışına qarşısını alma, dəyişdirmə, yenidən təşkil etmə hüququnu özündə saxlayır.</p>
            <p>1.4. Doktap elektron hüquqi yardım mobil tətbiqi İstifadəçilərin hər dəfə mobil tətbiqinə daxil olduqda bildiriş bölməsinə baş çəkmələrini tövsiyə edir.</p>
            <h1>2. Göstərilən xidmətlər</h1>
            <p>2.1.  Doktap elektron hüquqi yardım mobil tətbiq platformasında İstifadəçi müvafiq hüquq sahəsi üzrə Vəkil(lər) seçə və hüquqi yardım ala bilər.</p>
            <p>2.2.  ”E-İDEA” MMC və Vəkillər Kollegiyası xidmətlərin faktiki icraçısı deyil və  xidmətlər bu mobil tətbiqə öz məlumatlarını daxil etmiş Azərbaycan Respublikası Vəkillər Kollegiyasına üzv olan vəkil(lər) tərəfindən həyata keçirilir.</p>
            <h1>3. Xidmətlərdən istifadə</h1>
            <p>3.1. Xidmətlərin əksər imkanlarından istifadə etmək üçün İstifadəçi Xidmətlər üçün aktiv şəxsi istifadəçi hesabı yaratmalı və qeydiyyatdan keçməlidir (“Hesab”). Hesab açmaq üçün İstifadəçinin 18 yaşı və ya müvafiq qanunvericiliyə əsasən yetkinlik yaşı (18 yaşdan fərqli olarsa, və ya İstifadəçi başqa ölkə vətəndaşı olarsa) tamam olmalıdır. Hesabı qeydiyyatdan keçirmək üçün İstifadəçi öz şəxsi məlumatlarını - adını, e-mail ünvanını, mobil telefon nömrəsini və yaşını, eləcə də ən azı bir etibarlı ödəniş  vasitəsini (bank kartı məlumatı) Doktap-ə bildirməlidir. Siz Hesabınızda dəqiq, tam və yenilənmiş məlumatı qeyd etməyə razısınız. Siz Hesabınızda yanlış və ya etibarsız ödəniş metodu daxil olmaqla, dəqiq, tam və yenilənmiş məlumatı qeyd etmədikdə, bu, sizin Xidmətlərə çıxışınız və onlardan istifadənizi mümkün etməyə və ya Doktap ilə aranızda bağlanmış bu Razılaşmanın xitam verilməsinə səbəb ola bilər. Siz Hesabınızda baş verən bütün əməliyyatlar üçün məsuliyyət daşıyırsınız və Hesabınızın istifadəçi adı və şifrəni həmişə qorumaq və təhlükəsizlikdə saxlamağa razılaşırsınız.</p>
            <p>3.2. 18 yaşına çatmamış şəxslər Xidmətdən istifadə edə bilməz. Siz üçüncü tərəflərə Hesabınızdan istifadə etmək icazəsini verə bilməzsiniz. Hesabınızı hər hansı başqa fiziki və ya hüquqi şəxsə ötürə bilməzsiniz. Siz Xidmətlərdən istifadə edən zaman bütün müvafiq qanunlara əməl etməyə və Xidmətlərdən yalnız qanuni məqsədlər üçün istifadə etməyə razısınız. Müəyyən mərhələlərdə sizdən Xidmətlərə keçid almaq və onlardan istifadə etmək üçün şəxsiyyətinizi təsdiq etmək tələb edilə bilər və siz şəxsiyyətinizi təsdiq etməkdən imtina etdikdə Xidmətlərə keçid almaq və ya onlardan istifadə etmək müraciətiniz qəbul olunmaya bilər.</p>
            <p>3.3. Hesab yaratmaqla siz Xidmətlərdən istifadənizin normal iş fəaliyyətinin bir hissəsi kimi Xidmətlərin sizə mətn mesajları (SMS/email) göndərə biləcəyinə razılıq verirsiniz. Siz istənilən zaman app@doktap.az ünvanına e-məktub göndərməklə və mobil nömrənizi (mesajları qəbul edən nömrəniz) və daha bu cür mesajlar almaq istəmədiyinizi qeyd edərək belə mesajları almaqdan imtina edə bilərsiniz. Siz qəbul edirsiniz ki, mətn mesajlarından (SMS/email) imtina etmək Xidmətlərdən istifadənizə təsir edə bilər.</p>
            <h1>4. Ödəniş</h1>
            <p>4.1. İstifadəçi Vəkil(lər)lə konsultasiya mərhələsinə keçid etmək üçün müvafiq ödəniş həyata keçirməlidir. Bu mərhələdən sonra İstifadəçi və Vəkil(lər) arasında canlı ünsiyyət qurula və “Hüquqi yardım göstərilməsi haqqında” müqavilə bağlanıla bilər. İlkin konsultasiyanın davamı olaraq görüləcək işlərə görə yaranan xərclər (Vəkil(lər)in xidmət haqqı və digər rüsumlar) və digər məsələlərdə “E-İDEA” MMC və Vəkillər Kollegiyası heç bir rol oynamayacaq və bunlarla bağlı “E-İDEA” MMC qarşı heç bir tələb irəli sürülə bilməz. Ödəniş İstifadəçinin hesabına (mesaj bölməsinə) Vəkil(lər) tərəfindən konsultasiya ilə bağlı heç məlumat daxil olmadıqda geri qaytarıla bilər.</p>
            <h1>5. Əqli mülkiyyət hüquqları</h1>
            <p>5.1. Tətbiqdə bütün hüquqlar, mülkiyyət hüquqları və maraqları LEGALAİD MMC-ə məxsusdur və yalnız İstifadəçiyə tətbiqdən istifadə etmək üçün fərdi qeyri-müstəsna lisenziya verilir. Bu Razılaşmaya uyğun olaraq İstifadəçiyə tətbiqdən istifadə etməklə yanaşı xidmətlərin göstərilməsinə yönəlmiş digər məqsədlər üçün informasiyanın və məzmunun istifadəsi, köçürülməsi, saxlanması və ehtiyat nüsxələnməsi hüququ verilir.</p>
            <p>5.2 İstifadəçi heç bir şəkildə tətbiqin surətini çıxarmaq, dəyişdirmək, təkrarlanması, yenidən işlənməsi, kompilyasiyanı bərpa etmək və tətbiqə digər üsullarla daxil olmaq hüququna malik deyil.</p>
            <h1>6. Məxfilik</h1>
            <p>6.1. Doktap elektron hüquqi yardım mobil tətbiqə daxil edilmiş məlumatlar konsultasiya məqsədilə yalnız İstifadəçi tərəfindən seçilmiş Vəkil(lər)ə ötürülür. Bu mərhələdən sonra verilmiş məlumatların üçüncü şəxslərə ötürülməsinə görə Doktap elektron hüquqi yardım mobil tətbiqi deyil, İstifadəçi ilə ünsiyyətə keçmiş Vəkil(lər) məsuliyyət daşıyır.</p>
            <h1>7. Məsuliyyət</h1>
            <p>7.1. ”E-İDEA” MMC və Vəkillər Kollegiyası Vəkil(lər)in göstərdiyi xidmətlərə dair İstifadəçi və Vəkil(lər) arasında yaranmış mübahisələr və Vəkil(lər)in öz öhdəliklərini lazımi qaydada icra etməməsi ilə bağlı İstifadəçiyə dəyən zərərə  görə məsuliyyət daşımır.</p>
            <p>7.2. Qüvvədə olan qanunvericiliyə əsasən göstərilən xidmətlərə dair sənədləşmə tələb olunduğu təqdirdə, müvafiq lisenziyanın, sertifikatın və digər icazə sənədləşməsinin mövcudluğuna və etibarlılığına görə Vəkil(lər) məsuliyyət daşıyır.</p>
            <p>7.3. ”E-İDEA” MMC və Vəkillər Kollegiyasının məsuliyyəti Doktap elektron hüquqi yardım mobil tətbiqində elektron şəkildə konsultasiya alınması və xidmətlərin göstərilməsi məqsədilə Vəkil(lər) və İstifadəçi məlumatlarının yerləşdirilməsi ilə məhdudlaşır.</p>
            <p>7.4. “LEGALAİD” ММС Doktap elektron hüquqi yardım mobil tətbiqində təqdim edilmiş xidmətlər birbaşa olaraq Vəkil(lər) tərəfindən həyata keçirildiyinə görə göstərilmiş xidmətlərin keyfiyyətinə, təhlükəsizliyinə, istifadə imkanlarına, həmçinin digər xüsusiyyətlərinə zəmanət vermir. Buna görə də bütün pretenziyalar bilavasitə Vəkil(lər)ə ünvanlanmalıdır.</p>
            <p>7.5. İstifadəçi xidmət səviyyəsi ilə bağlı yaranmış sualları bilavasitə Vəkil(lər) ilə həll edə bilmədiyi təqdirdə “E-İDEA” MMC və Vəkillər Kollegiyasına Doktap elektron hüquqi yardım mobil tətbiqində qurulmuş çat və ya “Profil” bölməsində göstərilən dəstək xidmətinin elektron poçt ünvanına köməklik göstərilməsi üçün yazılı müraciət etmək hüququna malikdir. Bu halda “E-İDEA” MMC və  Vəkillər Kollegiyası İstifaəçi ilə Vəkil(lər) arasında yaranmış mübahisənin həll edilməsi üçün köməklik göstərməyə çalışacaq. Mübahisənin həll edilməsi mümkün olmadıqda, “E-İDEA” MMC və Vəkillər Kollegiyası  öz mülahizəsinə əsasən İstifadəçiyə ödənilmiş məbləğ geri qaytara bilər.</p>
            <p>7.6. Hazırkı Razılaşmanın icrası nəticəsində yaranmış mübahisələr məhkəməyəqədər pretenziya qaydasında və Azərbaycan Qanunvericiliyinə əsasən həll edilməlidir.</p>
            <p>7.7. Pretenziyalar İstifadəçi tərəfindən Doktap elektron hüquqi yardım mobil tətbiqin “Profil” bölməsində göstərilən dəstək xidmətinə və ya mütləq şəkildə məktub mövzusunu “Pretenziya” qeyd etməklə app@doktap.az  elektron poçt ünvanına göndərilməlidir.</p>
            <p>7.8. Pretenziyaların hazırkı Razılaşmanın 7.7-ci bəndində göstərilmiş üsuldan fərqli təqdim edilməsi yolverilməzdir.</p>
            <p>7.9. Pretenziya qəbul edildiyi tarixindən 30 (otuz) iş günü ərzində baxılır.</p>
        </div>
    </Layout>
  );
}
