import React from "react";
import Layout from "../components/layout/Layout";
import '../assets/styles/sass/Policy/PrivacyPolicy.sass'

export default function ReturnPolicy() {
  return (
    <Layout>
        <div className="mainPolicy">
            <h1>RETURN POLICY</h1>
            <p>
              Thank you for your purchase. We hope you are happy with your purchase. However, if you are not completely satisfied with your purchase for any reason, you may return it to us for a full refund only. Please see below for more information on our return policy.
            </p>
            <h1>RETURNS</h1>
            <p>  
              All returns must be postmarked within one (1) days of the purchase date. All returned items must be in new and unused condition, with all original tags and labels attached.
            </p>
            <h1>RETURN PROCESS</h1>
            <p>  
              To return an item, please email customer service at info@doktap.az to obtain a Return Merchandise Authorisation (RMA) number. After receiving a RMA number, place the item securely in its original packaging and include your proof of purchase, then mail your return to the following address:<br /> <br />
                E-Services LLC<br />
                Attn: Returns <br />
                RMA # <br />
                __________ <br />
                Baku, AZ1000 <br />
                Azerbaijan <br />

                Return shipping charges will be paid or reimbursed by us. <br />
            </p>
            <h1>REFUNDS</h1>
            <p>  
                After receiving your return and inspecting the condition of your item, we will process your return. Please allow at least seven (7) days from the receipt of your item to process your return. Refunds may take 1-2 billing cycles to appear on your credit card statement, depending on your credit card company. We will notify you by email when your return has been processed.
            </p>
            <h1>EXCEPTIONS</h1>
            <p>  
                For defective or damaged products, please contact us at the contact details below to arrange a refund or exchange.
            </p>
            <h1>QUESTIONS</h1>
            <p>  
                If you have any questions concerning our return policy, please contact us at: <br /> <br />
                    +994516144295 <br />
                    info@doktap.az
            </p>
        </div>
    </Layout>
  );
}