import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import "../assets/styles/sass/Modal/OPTModal.sass";
import { ReactComponent as CloseIcon } from "../assets/static/close-modal.svg";
import "../assets/styles/sass/Modal/ForgetConfirmModal.sass";
import axiosData from "../helpers/axiosData";
import ResetPasswordModal from "./ResetPasswordModal";
import { t } from "i18next";

export default function SecurityCodeModal(props) {
  const [code, setCode] = useState("");
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);

  const handleModalClose = () => {
    props.setSecurityModalOpen(false);
  };

  const handleForgetPassword = () => {
    axiosData
      .patch("/doctor/auth/forgot-password/", {
        token: props.forgetData.token,
        code: code,
      })
      .then((res) => {
        handleModalClose();
        setResetPasswordModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBackLogin = () => {
    handleModalClose();
    props.setLoginModalOpen(true);
  };

  return (
    <div>
      <Modal
        open={props.securityModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="boxContainer">
          <div className="titleContainer">
            <h1 id="header-title" variant="h6" component="h2">
              {t('enter_security_code')}
            </h1>
            <p id="modal-modal-title">
              {t('code_sent_to')}{" "}
              {props.type === "sms"
                ? props.forgetData.phone
                : props.forgetData.email}
            </p>
            <h3 id="modal-modal-title">
              <input
                type="text"
                placeholder={t('security_code')}
                maxLength={6}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </h3>
            <div className="forgetButtons">
              <button className="continueButton" onClick={handleForgetPassword}>
                {t('continue')}
              </button>
              <button className="backLoginButton" onClick={handleBackLogin}>
               {t('back_to_login')}
              </button>
            </div>
            <div className="closeContainer" onClick={() => handleModalClose()}>
              <CloseIcon />
            </div>
          </div>
        </div>
      </Modal>
      <ResetPasswordModal
        resetPasswordOpen={resetPasswordModalOpen}
        setResetPasswordOpen={setResetPasswordModalOpen}
        forgetData={props.forgetData}
      />
    </div>
  );
}
