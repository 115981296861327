import axios from "axios";
import {toast } from 'react-toastify';

let api =  axios.create({
  baseURL: `${process.env.REACT_APP_MAIN_DOMAIN}/api/v1/`,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "App-Token": process.env.REACT_APP_TOKEN,
    'Accept-Language': localStorage.getItem('language') || "az",
  },
});

api.interceptors.response.use((response) => {
  if(response.status === 201){
    toast.success(response.data.message);
  }
  return response;
}, (error) => {
  console.log(error)
  if(error.response.status === 400 || error.response.status === 401 || error.response.status === 403 || error.response.status === 404) {
    toast.error(error.response.data.message);
  }
  else {
    toast.error('Some Problem Occured!');
  }

  throw error;
});

export default api;
