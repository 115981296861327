import * as React from "react";
import Modal from "@mui/material/Modal";
import "../assets/styles/sass/Modal/LoginModal.sass";
import { ReactComponent as CloseIcon } from "../assets/static/close-modal.svg";
import { ReactComponent as TieIcon } from "../assets/static/tie.svg";
import { ReactComponent as UserIcon } from "../assets/static/user.svg";
import { ReactComponent as CheckBoxFilled } from "../assets/static/checkbox-filled.svg";
import { ReactComponent as CheckBoxUnFilled } from "../assets/static/checkbox-unfilled.svg";
import { useState, useContext } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import axiosData from "../helpers/axiosData";
import OptModal from "./OPTModal";
import AuthContext from "../context/AuthProvider";
import UserRegisterModal from "./UserRegisterModal";
import ForgetConfirmModal from "./ForgetConfirmModal";
import { t } from "i18next";

export default function LoginModal(props) {
  const { setAuth } = useContext(AuthContext);
  const [isUserSelected, setUserSelected] = useState(true);
  const [isDoctorSelected, setDoctorSelected] = useState(false);
  const [optModalOpen, setOptModalOpen] = useState(false);
  const [forgetOpen, setForgetOpen] = useState(false);
  const [userRegisterOpen, setUserRegisterOpen] = useState(false);
  const [isPasswordShowing, setPasswordShowing] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgetData, setForgetData] = useState({});

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleUserSelected = () => {
    setUserSelected(true);
    setDoctorSelected(false);
  };

  const handleDoctorSelected = () => {
    setDoctorSelected(true);
    setUserSelected(false);
  };
  function handleKeyPress(e) {
    const regex = /[0-9]/;
    const key = e.key;
  
    if (key === "Enter") {
      handleLogin();
    } else if (!regex.test(key)) {
      e.preventDefault();
    }
  }
  const handleLogin = () => {
    axiosData
      .post("/user/auth/login/", {
        phone: `994${phone}`,
      })
      .then((res) => {
        props.setOpen(false);
        setOptModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRegister = () => {
    props.setOpen(false);
    setUserRegisterOpen(true);
  };

  const handleForgetOpen = () => {
    axiosData
      .post("/doctor/auth/forgot-password/", {
        email: email,
      })
      .then((res) => {
        setForgetData(res.data.body);
        props.setOpen(false);
        setForgetOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDoctorLogin = () => {
    axiosData
      .post("/doctor/auth/login/", {
        email: email,
        password: password,
      })
      .then((res) => {
        let token = res.data.body.access;
        axiosData
          .get("doctor/account/personal-info/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const type = "doctor";
            window.open(
              process.env.REACT_APP_DOCTOR_URL + "?token=" + token,
              "_self"
            );
            let user = res.data.body;
            localStorage.setItem("token", token);
            localStorage.setItem("type", type);
            setAuth({ user, type });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            props.setOpen(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="boxContainer">
          <h1 id="modal-modal-title" variant="h6" component="h2">
          {t('sign_in_e_doctor')}
          </h1>
          <div className="loginTypeSelector">
            <div
              className={"selectorItem " + (isUserSelected && "selected ")}
              onClick={() => handleUserSelected()}
            >
              <UserIcon fill={isUserSelected ? "#58C27D" : "#777E91"} />
              <p>{t('as_a_user')}</p>
              {isUserSelected ? <CheckBoxFilled /> : <CheckBoxUnFilled />}
            </div>
            <div
              className={"selectorItem " + (isDoctorSelected && "selected ")}
              onClick={() => handleDoctorSelected()}
            >
              <TieIcon fill={isDoctorSelected ? "#58C27D" : "#777E91"} />
              <p>{t('as_a_doctor')}</p>
              {isDoctorSelected ? <CheckBoxFilled /> : <CheckBoxUnFilled />}
            </div>
          </div>
          <div className="closeContainer" onClick={() => handleClose()}>
            <CloseIcon />
          </div>
          {isUserSelected && (
            <div className="bottomContainer">
              <div className="mobileInput">
                <p>{t('use_phone_for_sign_in')}</p>
                <div className="inputContainer">
                  <span>+ 994</span>
                  <input
                    placeholder={t('mobile_number')}
                    className="phoneInput input-padding"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                    type="number"
                    // onKeyPress={(e) => e.key === "Enter" && handleLogin()}
                    onKeyPress={(e) => handleKeyPress(e)}
                  />
                  <BsFillArrowRightCircleFill
                    className="inputButton"
                    color="#3B71FE"
                    size={32}
                    onClick={() => handleLogin()}
                  />
                </div>
              </div>

              <div className="continueOptions">
                <button className="loginButton" onClick={() => handleLogin()}>
                {t('login')}
                </button>
                <p onClick={handleRegister}>{t('dont_have_account')} <span>{t('register')}</span></p>
              </div>
              {/* <div className="continueOptions">
                <div className="buttonOptions">
                  <div className="signInButton">
                    <AiFillApple size={20} />
                    Sign in with Apple
                  </div>
                  <div className="signInButton">
                    <FcGoogle />
                    Sign in with Google
                  </div>
                  <div className="signInButton">
                    <FaFacebook color="#1877F2" />
                    Sign in with Facebook
                  </div>
                </div>
              </div> */}
            </div>
          )}

          {isDoctorSelected && (
            <div className="bottomContainer">
              <div className="mobileInput">
                <p>{t('sign_in_as_a_doctor')}</p>
                <div className="inputContainer">
                  <input
                    placeholder="Username/E-mail"
                    className="phoneInput"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="inputContainer">
                  <input
                    placeholder={t('password')}
                    className="phoneInput"
                    type={isPasswordShowing ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleDoctorLogin()}
                  />
                  {isPasswordShowing ? (
                    <AiOutlineEyeInvisible
                      className="inputButton eye"
                      color="#777E91"
                      size={30}
                      onClick={() => setPasswordShowing(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      className="inputButton eye"
                      color="#777E91"
                      size={30}
                      onClick={() => setPasswordShowing(true)}
                    />
                  )}
                </div>
                <button
                  className="loginButton"
                  onClick={() => handleDoctorLogin()}
                >
                  {t('login')}
                </button>
              </div>

              <div className="continueOptions">
                <p onClick={handleForgetOpen}>{t('forgot_password')}</p>
                {/* <div className='registerButtonOption'>
                  <div className='registerButton'>
                    Register
                  </div>
                </div> */}
              </div>
            </div>
          )}
        </div>
      </Modal>
      <OptModal
        otpOpen={optModalOpen}
        setOtpOpen={() => setOptModalOpen()}
        phone={phone}
      />
      <ForgetConfirmModal
        forgetOpen={forgetOpen}
        setForgetOpen={() => setForgetOpen()}
        setLoginModalOpen={props.setOpen}
        forgetData={forgetData}
      />
      <UserRegisterModal
        registerOpen={userRegisterOpen}
        setRegisterOpen={() => setUserRegisterOpen()}
      />
    </div>
  );
}
