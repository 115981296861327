import * as React from "react";
import Modal from "@mui/material/Modal";
import "../assets/styles/sass/Modal/LoginModal.sass";
import { ReactComponent as CloseIcon } from "../assets/static/close-modal.svg";
import { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import axiosData from "../helpers/axiosData";
import OptModal from "./OPTModal";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { toast } from "react-toastify";

export default function UserRegisterModal(props) {
  const [optModalOpen, setOptModalOpen] = useState(false);
  const [isPasswordShowing, setPasswordShowing] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [password, setPassword] = useState("");
  const [isPolicyAccept, setIsPolicyAccept] = useState(false);
  const [errors, setErrors] = useState({});


  const handleClose = () => {
    props.setRegisterOpen(false);
  };

  function handleKeyPress(e) {
    const regex = /[a-zA-Z]/;
    const key = e.key;
  
    if (key === "Enter") {
      handleRegister();
    } else if (!regex.test(key)) {
      e.preventDefault();
    }
  }

  function handleKeyPressNumber(e) {
    const regex = /[0-9]/;
    const key = e.key;
  
    if (key === "Enter") {
      handleRegister();
    } else if (!regex.test(key)) {
      e.preventDefault();
    }
  }

  
  const validateInput = (value) => {
    return value.trim() !== "";
  };

  const handleInputChange = (e, setter) => {
    const inputValue = e.target.value;
    setter(inputValue);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: false,
    }));
  };

  const handleRegister = () => {
    if(!isPolicyAccept){
      toast.error(t('policy_accept'));
      return;
    }

    const inputFields = ['phone', 'email', 'name', 'surname', 'patronymic', 'birthdate', 'password'];
    const newErrors = {};

    inputFields.forEach((field) => {
      if (!validateInput(eval(field))) {
        newErrors[field] = true;
      }
    });
    setErrors(newErrors);

    axiosData
      .post("/user/auth/register/", {
        birthdate: birthdate,
        phone: `994${phone}`,
        email: email,
        password: password,
        first_name: name,
        last_name: surname,
        father_name: patronymic,
        confirmPassword: password,
      })
      .then((res) => {
        handleClose();
        setOptModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        open={props.registerOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="boxContainer">
          <h1 id="modal-modal-title" variant="h6" component="h2">
            {t('register_on_doctor')}
          </h1>
          <div className="closeContainer" onClick={() => handleClose()}>
            <CloseIcon />
          </div>
          <div className="bottomContainer">
            <div className="mobileInput">
              {/* <p>Sign up as a User</p> */}
              <div className="inputContainer">
                <input
                  placeholder={t('email')}
                  className="phoneInput"
                  value={email}
                  name="email"
                  // onChange={(event) => setEmail(event.target.value)}
                  onChange={(e) => handleInputChange(e, setEmail)}
                  style={{ borderColor: errors.email ? 'red' : '' }}
                />
              </div>
              <div className="inputContainer">
                <input
                  placeholder={t('name')}
                  className="phoneInput"
                  value={name}
                  name="name"
                  // onChange={(event) => setName(event.target.value)}
                  onChange={(e) => handleInputChange(e, setName)}
                  onKeyPress={(e) => handleKeyPress(e)}
                  style={{ borderColor: errors.name ? 'red' : '' }}
                />
              </div>
              <div className="inputContainer">
                <input
                  placeholder={t('surname')}
                  className="phoneInput"
                  value={surname}
                  name="surname"
                  onChange={(e) => handleInputChange(e, setSurname)}
                  onKeyPress={(e) => handleKeyPress(e)}
                  style={{ borderColor: errors.surname ? 'red' : '' }}
                />
              </div>
              <div className="inputContainer">
                <input
                  placeholder={t("patronymic")}
                  className="phoneInput"
                  value={patronymic}
                  name="patronymic"
                  onChange={(e) => handleInputChange(e, setPatronymic)}
                  onKeyPress={(e) => handleKeyPress(e)}
                  style={{ borderColor: errors.patronymic ? 'red' : '' }}
                />
              </div>
              <div className="inputContainer">
                <span>+ 994</span>
                <input
                  placeholder={t('phone')}
                  className="phoneInput input-padding"
                  value={phone}
                  name="phone"
                  onChange={(e) => handleInputChange(e, setPhone)}
                  onKeyPress={(e) => handleKeyPressNumber(e)}
                  style={{ borderColor: errors.phone ? 'red' : '' }}
                />
              </div>
              <div className="inputContainer">
                <input
                  type="date"
                  placeholder={t('birthdate')}
                  className="phoneInput"
                  max='2022-01-01'
                  value={birthdate}
                  name="birthdate"
                  onChange={(e) => handleInputChange(e, setBirthdate)}
                  style={{ borderColor: errors.birthdate ? 'red' : '' }}
                />
              </div>
              <div className="inputContainer">
                <input
                  placeholder={t('password')}
                  className="phoneInput"
                  type={isPasswordShowing ? "text" : "password"}
                  value={password}
                  name="password"
                  onChange={(e) => handleInputChange(e, setPassword)}
                  style={{ borderColor: errors.password ? 'red' : '' }}
                />
                <AiOutlineEye
                  className="inputButton eye"
                  color="#777E91"
                  size={30}
                  onClick={() =>
                    setPasswordShowing(
                      (isPasswordShowing) => !isPasswordShowing
                    )
                  }
                />
              </div>
              {/* <div className="inputContainer">
                <input
                  placeholder="Confirm Password"
                  className="phoneInput"
                  type={isPasswordShowing ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                /> */}
              {/* </div> */}
              <div className="agreementContainer">
                <input type='checkbox' id="agreement" 
                  checked={isPolicyAccept}
                  onChange={() =>
                    setIsPolicyAccept(isPolicyAccept => !isPolicyAccept)
                  } />
                <label htmlFor="agreement">Accept the <Link to={'/user-agreement'}>end-user agreement</Link> & <Link to={'/privacy-policy'}>privacy policy</Link></label>
              </div>
              <button className="loginButton" onClick={() => handleRegister()}>
                {t('register')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <OptModal
        otpOpen={optModalOpen}
        setOtpOpen={() => setOptModalOpen()}
        phone={phone}
        isRegister={true}
      />
    </div>
  );
}
