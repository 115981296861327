import {createContext, useState} from 'react'; 
import axiosData from '../helpers/axiosData'

const AuthContext = createContext({});


export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState();

    
    if(localStorage.getItem('type') === 'doctor'){
        axiosData.get('doctor/account/personal-info/',
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` 
          }
        }
      ).then(()=>{
        setAuth(localStorage.getItem('token'), localStorage.getItem('type'))
      }).catch((err)=>{
        if(err.response.status === 401){
          setAuth(null);
          localStorage.removeItem('token');
          localStorage.removeItem('type');
        }
      })
    }else if (localStorage.getItem('type') === 'user'){
        axiosData.get('user/settings/personal-info/',
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` 
          }
        }
      ).then(()=>{
        setAuth(localStorage.getItem('token'), localStorage.getItem('type'))
      }).catch((err)=>{
        if(err.response.status === 401){
          setAuth(null);
          localStorage.removeItem('token');
          localStorage.removeItem('type');
        }
      })
    }

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;