import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import FaqComponent from "../components/Faq";
import axiosData from "../helpers/axiosData";

export default function Faq(props) {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    axiosData.get("faqs").then((res) => {
      setFaqs(res.data.body);
    });
  }, []);

  return (
    <div className="mainContainer">
      <Layout>
        <FaqComponent faqs={faqs} />
      </Layout>
    </div>
  );
}
