import React, { useState, useEffect, useContext } from "react";
import Illustration from "../../assets/static/illustration.png";
import "../../assets/styles/sass/Dashboard/Dashboard.sass";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import AmazingService from "../AmazingService/index.jsx";
import axiosData from "../../helpers/axiosData";
import Header from "../Header/Index.jsx";
import Trusted from "../Trusted/Index.jsx";
import { useTranslation } from "react-i18next";
import LoginModal from "../../Modals/LoginModal";
import AuthContext from "../../context/AuthProvider";

export default function Index() {
  const { auth } = useContext(AuthContext);
  const { t } = useTranslation();

  const [easyStepData, setEasyStepData] = useState([]);
  const [legalData, setLegalData] = useState([]);
  const [earnMoreData, setEarnMoreData] = useState([]);
  const [subscribedPhone, setSubscribedPhone] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalOpen = () => {
    setShowLoginModal(true);
  };
  const getProfilePage = () => {
    let token = localStorage.getItem("token");
    let type = localStorage.getItem("type");
    if (type === "user") {
      window.open(process.env.REACT_APP_USER_URL + "?token=" + token, "_self");
    } else if (type === "doctor") {
      window.open(
        process.env.REACT_APP_DOCTOR_URL + "?token=" + token,
        "_self"
      );
    }
  };

  useEffect(() => {
    axiosData.get("easy-step").then((res) => {
      console.log('easy', res)
      setEasyStepData(res.data.body);
    });
    axiosData.get("legal").then((res) => {
      setLegalData(res.data.body);
    });
    axiosData.get("earn-more").then((res) => {
      setEarnMoreData(res.data.body);
    });
  }, []);

  const handlePhoneSubscription = () => {
    axiosData
      .post("subscribed-phone/", {
        phone: subscribedPhone,
      })
      .then((res) => { });
  };

  return (
    <div>
      <Header />
      <div className="middleContainer">
        <img
          className="illustrationImage"
          src={Illustration}
          alt="Main Illustation"
        />
        <div>
          <h1>{easyStepData.title}</h1>
          <p className="stackText">{easyStepData.description}</p>
          <div>
            <div className="buttonContainer">
              <button className="button" style={{ backgroundColor: "#58C27D" }}>
                01
              </button>
              <p className="text">{t("register")}</p>
            </div>
            <div className="buttonContainer">
              <button className="button" style={{ backgroundColor: "#92A5EF" }}>
                02
              </button>
              <p className="text">{t("book_doctor")}</p>
            </div>
            <div className="buttonContainer">
              <button className="button" style={{ backgroundColor: "#FA8F54" }}>
                03
              </button>
              <p className="text">{t("enjoy_service")}</p>
            </div>
          </div>
          <div className="inputContainer">
            <input
              placeholder={t('enter_your_phone_number')}
              className="phoneInput"
              value={subscribedPhone}
              onChange={(e) => setSubscribedPhone(e.target.value)}
            />
            <BsFillArrowRightCircleFill
              className="inputButton"
              color="#3B71FE"
              size={32}
              onClick={() => handlePhoneSubscription()}
            />
          </div>
        </div>
      </div>
      <AmazingService />
      <div className="phoneContainer">
        <div className="phoneLeft">
          <h1>{legalData.title}</h1>
          <p>{legalData.description}</p>
          <LoginModal open={showLoginModal} setOpen={setShowLoginModal} />
          {auth ? (
            <button onClick={() => getProfilePage()} className="button">{t("get_started")}</button>
          ) : (
            <button onClick={() => handleModalOpen()} className="button">{t("get_started")}</button>
          )}
        </div>
        <img
          className="phone"
          src={require("../../assets/static/phone_mockup.png")}
          alt="Phone Mockup"
        />
      </div>
      <div className="doctorContainer">
        <img
          className="doctor"
          src={require("../../assets/static/doctor_include.png")}
          alt="Doctor Mockup"
        />
        <div className="doctorRight">
          <h1>{earnMoreData.title}</h1>
          <p>{earnMoreData.description}</p>
          <div className="inputContainer">
            <input
              placeholder={t('enter_your_phone_number')}
              className="phoneInput"
              type="number"
              value={subscribedPhone}
              onChange={(e) => setSubscribedPhone(e.target.value)}
            />
            <BsFillArrowRightCircleFill
              className="inputButton"
              color="#3B71FE"
              size={32}
              onClick={() => handlePhoneSubscription()}
            />
          </div>
        </div>
      </div>
      <Trusted />
    </div>
  );
}
