import React from "react";
import "../../assets/styles/sass/Faq/Faq.sass";
import FaqItem from "../FaqItem/Index";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Index(props) {
  const { t } = useTranslation();

  return (
    <div className="faqContainer">
      <h1>{t("faq")}</h1>
      <div className="tabsContainer">
        <Tabs defaultActiveKey="0" className="tabs">
          {props.faqs.map((faq, key) => {
            return (
              <Tab eventKey={key} title={faq.title} color="#fff" key={key}>
                {faq.faqs.map((faqData, key) => {
                  return (
                    <FaqItem
                      number={key < 10 ? "0" + ++key : ++key}
                      faqData={faqData}
                      key={key}
                    />
                  );
                })}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
}
