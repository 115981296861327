import React, { useState, useEffect } from "react";
import "../../assets/styles/sass/Dashboard/Dashboard.sass";
import axiosData from "../../helpers/axiosData";

export default function Index() {
  const [trustedData, setTrustedData] = useState([]);

  useEffect(() => {
    axiosData.get("trusted").then((res) => {
      setTrustedData(res.data.body);
    });
  }, []);

  return (
    <div className="logosContainer">
      <div className="textContainer">
        <h1>{trustedData.title}</h1>
        <p>{trustedData.description}</p>
      </div>
      <div className="logos">
        {trustedData.partners?.map((partner, index) =>
          <img
            key={index}
            src={partner.image}
            alt=""
          />
        )}
        
      </div>
    </div>
  );
}
