import React, { useState, useEffect } from "react";
import MainHeader from "../../assets/static/main_header.png";
import "../../assets/styles/sass/Dashboard/Dashboard.sass";
import "../../assets/styles/sass/About/About.sass";
import AmazingService from "../AmazingService/index.jsx";
import axiosData from "../../helpers/axiosData";
import Header from "../Header/Index.jsx";
import { t } from "i18next";
import Trusted from "../Trusted/Index.jsx";

export default function Index() {
  const [aboutData, setAboutData] = useState([]);
  const [showFullText, setShowFullText] = useState(false);
  const htmlText = aboutData.description;
  const maxWords = 36;

  useEffect(() => {
    axiosData.get("about").then((res) => {
      setAboutData(res.data.body);
    });
  }, []);

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };
  const wordCount = stripHtml(htmlText).split(" ").length;

  return (
    <div>
      <Header />
      {/* <div className="aboutContainer">
        <h1 className="cardHeader">{aboutData?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: aboutData?.description }}></div>
        <button>{t("show_more")}</button>
      </div> */}

      <div className="aboutContainer">
        <h1 className="cardHeader">{aboutData?.title}</h1>
        {wordCount <= maxWords || showFullText ? (
          <div dangerouslySetInnerHTML={{ __html: htmlText }} />
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  htmlText.split(" ").slice(0, maxWords).join(" ") + "...",
              }}
            />
          </>
        )}
        <button onClick={() => setShowFullText(!showFullText)}>
          {t(showFullText ? "show_less" : "show_more")}
        </button>
      </div>
      <AmazingService />
      <Trusted />
    </div>
  );
}
