import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import "../assets/styles/sass/Modal/OPTModal.sass";
import { ReactComponent as CloseIcon } from "../assets/static/close-modal.svg";
import "../assets/styles/sass/Modal/ForgetConfirmModal.sass";
import axiosData from "../helpers/axiosData";
import SecurityCodeModal from "./SecurityCodeModal";
import { t } from "i18next";

export default function ForgetConfirmModal(props) {
  const [forgetType, setForgetType] = useState("");
  const [securityModalOpen, setSecurityModalOpen] = useState(false);

  const handleModalClose = () => {
    props.setForgetOpen(false);
  };

  const handleForgetPassword = () => {
    axiosData
      .put("/doctor/auth/forgot-password/", {
        type: forgetType,
        token: props.forgetData.token,
      })
      .then((res) => {
        handleModalClose();
        setSecurityModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBackLogin = () => {
    handleModalClose();
    props.setLoginModalOpen(true);
  };

  return (
    <div>
      <Modal
        open={props.forgetOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="boxContainer">
          <div className="titleContainer">
            <h1 id="header-title" variant="h6" component="h2">
              {t('lets_confirm_you')}
            </h1>
            <p id="modal-modal-title">
             {t('help_secure')}
            </p>
            <h3 id="modal-modal-title" className="text-checbox">
              <input
                type="radio"
                name="forgetType"
                id="sms"
                value={"sms"}
                onChange={(e) => setForgetType(e.target.value)}
              />
              {t('get_message_by_code')} {props.forgetData.phone}
            </h3>
            <br />
            <hr />
            <h3 id="modal-modal-title" className="text-checbox">
              <input
                type="radio"
                name="forgetType"
                id="email"
                value={"mail"}
                onChange={(e) => setForgetType(e.target.value)}
              />
              {t('get_message_by_email')} {props.forgetData.email}
            </h3>
            <div className="forgetButtons">
              <button className="continueButton" onClick={handleForgetPassword}>
                {t('continue')}
              </button>
              <button className="backLoginButton" onClick={handleBackLogin}>
              {t('back_to_login')}
              </button>
            </div>
            <div className="closeContainer" onClick={() => handleModalClose()}>
              <CloseIcon />
            </div>
          </div>
        </div>
      </Modal>
      <SecurityCodeModal
        securityModalOpen={securityModalOpen}
        setSecurityModalOpen={() => setSecurityModalOpen()}
        forgetData={props.forgetData}
        type={forgetType}
        setLoginModalOpen={props.setLoginModalOpen}
      />
    </div>
  );
}
