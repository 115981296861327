import React, { useState, useEffect } from "react";
import "../../assets/styles/sass/Contact/Contact.sass";
import { ReactComponent as Pin } from "../../assets/static/pin_icon.svg";
import { ReactComponent as Email } from "../../assets/static/email_icon.svg";
import { ReactComponent as Phone } from "../../assets/static/phone_icon.svg";
import axiosData from "../../helpers/axiosData";
import { t } from "i18next";

export default function Index() {
  const [contactData, setContactData] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    axiosData.get("contact-us").then((res) => {
      setContactData(res.data.body);
    });
  }, []);

  const addContactData = () => {
    axiosData
      .post("question/", {
        fullName: fullName,
        email: email,
        message: message,
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="contactContainer">
      <div className="leftContainer">
        <h1>{t('have_a_question')}</h1>
        <div className="formContainer">
          <div className="singleInputRow">
            <p>{t('full_name')}</p>
            <input
              type="text"
              placeholder={t('type_your_full_name')}
              defaultValue={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="singleInputRow">
            <p>{t('email')}</p>
            <input
              type="text"
              placeholder="app@doktap.az"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="singleInputRow">
            <p>{t('message')}</p>
            <textarea
              placeholder={t('share_your_throughts')}
              defaultValue={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="buttonContainer" onClick={() => addContactData()}>
          <button>{t('send')}</button>
        </div>
      </div>
      <div className="rightContainer">
        <h1>{t('contact_us')}</h1>
        <div>
          <div className="informationContainer">
            <div className="contactInformation">
              <Email className="icon" />
              <p>{contactData.email}</p>
            </div>
            <div className="contactInformation">
              <Phone className="icon" />
              <p>{contactData.phone}</p>
            </div>
          </div>
          <div className="informationContainer">
            <div className="contactInformation">
              <Pin className="icon" />
              <p>{contactData.address}</p>
            </div>
          </div>
          <div className="google-map">
            <iframe
              title="Map"
              frameBorder="0"
              scrolling="no"
              src="https://maps.google.com/maps?q=40.375042240439065,49.86181462978452&hl=en&z=14&amp;output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
