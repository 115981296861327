import React, { useState, useContext, useEffect } from "react";
import Modal from "@mui/material/Modal";
import "../assets/styles/sass/Modal/OPTModal.sass";
import { ReactComponent as CloseIcon } from "../assets/static/close-modal.svg";
import OTPInput from "otp-input-react";
import axiosData from "../helpers/axiosData";
import AuthContext from "../context/AuthProvider";
import { t } from "i18next";

export default function OptModal(props) {
  const { setAuth } = useContext(AuthContext);

  const [otp, setOtp] = useState("");

  const handleOtpClose = () => {
    props.setOtpOpen(false);
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleLogin();
    }
  }, [otp]);

  const handleLogin = () => {
    axiosData
      .put(
        props.isRegister ? "/user/auth/register-confirm/" : "/user/auth/login/",
        {
          phone: `994${props.phone}`,
          code: otp,
        }
      )
      .then((res) => {
        let token = res.data.body.access;
        handleOtpClose();
        axiosData
          .get("user/settings/personal-info/", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            const type = "user";
            window.open(
              process.env.REACT_APP_USER_URL + "?token=" + token,
              "_self"
            );
            let user = res.data.body;
            setAuth({ user, type });
            localStorage.setItem("token", token);
            localStorage.setItem("type", type);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        open={props.otpOpen}
        onClose={handleOtpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="boxContainer">
          <h1 id="header-title" variant="h6" component="h2">
            {t('enter_one_time_code')}
          </h1>
          <p id="modal-modal-title">{t('code_sent_to')} +994 {props.phone}.</p>
          <h3 id="modal-modal-title">{t('doesnt_number_belongs_to_you')}</h3>
          <div
            className="otpInput"
            onKeyPress={(e) => e.key === "Enter" && handleLogin()}
          >
            <OTPInput
              value={otp}
              placeholder={0}
              onChange={setOtp}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              shouldAutoFocus={true}
            />
          </div>
          <p id="modal-modal-title" onClick={() => handleLogin()}>
            {t('resend_code')}
          </p>
          <div className="closeContainer" onClick={() => handleOtpClose()}>
            <CloseIcon />
          </div>
        </div>
      </Modal>
    </div>
  );
}
