import React, { Component } from "react";
import "../../assets/styles/css/Footer.css";
import { ReactComponent as Apple } from "../../assets/static/appstore.svg";
import { ReactComponent as AppleLarge } from "../../assets/static/appstore_large.svg";
import { ReactComponent as GooglePlay } from "../../assets/static/googleplay.svg";
import { ReactComponent as GooglePlayLarge } from "../../assets/static/googleplay_large.svg";
import { ReactComponent as AppGallery } from "../../assets/static/appgallery.svg";
import { ReactComponent as AppGalleryLarge } from "../../assets/static/appgallery_large.svg";
import { ReactComponent as PhoneFrame } from "../../assets/static/phone_frame.svg";
import { ReactComponent as Qr } from "../../assets/static/qr.svg";
import { ReactComponent as Logo } from "../../assets/static/logo.svg";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import axiosData from "../../helpers/axiosData";
import { t } from "i18next";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: window.matchMedia("(min-width: 769px)").matches,

      email: "",
      ourAppData: [],
    };
  }

  getOurAppData = async () => {
    const ourAppData = await axiosData.get("/our-app");
    this.setState({ ourAppData: ourAppData.data.body });
  };

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 769px)").addEventListener("change", handler);
    this.getOurAppData();
  }

  subscribe = () => {
    axiosData
      .post("subscribed-email/", {
        email: this.state.email,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  render() {
    return (
      <div>
        <div className="footerContainer">
          <div className="appContainer">
            <div className="insideContainer">
              <div>
                <div className="downloadText">
                  <h1>{this.state.ourAppData.title}</h1>
                  <p>{this.state.ourAppData.description}</p>
                </div>
                <div></div>
                {this.state.matches ? (
                  <div className="logoContainer">
                    <a
                      href="https://play.google.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GooglePlay className="logo" />
                    </a>
                    <a
                      href="https://www.apple.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Apple className="logo" />
                    </a>
                    <a
                      href="https://appgallery.huawei.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AppGallery className="logo" />
                    </a>
                  </div>
                ) : (
                  <div className="logoContainer">
                    <a
                      href="https://play.google.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GooglePlayLarge className="logo" />
                    </a>
                    <a
                      href="https://www.apple.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AppleLarge className="logo" />
                    </a>
                    <a
                      href="https://appgallery.huawei.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <AppGalleryLarge className="logo" />
                    </a>
                  </div>
                )}
              </div>
              <div className="phone">
                <PhoneFrame className="phoneFrame" />
              </div>
              <div className="scan">
                <Qr className="phoneFrame" style={{ marginBottom: "20px" }} />
              </div>
            </div>
          </div>
          <div className="footerMidContainer">
            <Link to="/" className="Logo">
              <Logo style={{ scale: "1.4", marginRight: "20px" }} />
              <p>DOKTAP</p>
              {/* <img src={require('../../assets/static/Logo.png')} className="Logo" style={{ marginLeft: "-9px" }} alt="Logo" /> */}
            </Link>
            <div>
              <div className="linkContainer">
                <Link to="/about">{t("about_us")}</Link>
                <a href={process.env.REACT_APP_DOCTOR_URL}>
                  {t("for_doctors")}
                </a>
                <a href="/contact">{t("contact_us")}</a>
                {/* <a href="/cookie-policy">{t('contact_us')}</a> */}
              </div>
              <div className="linkContainer">
                <a href="/support">{t("support")}</a>
                <Link to={"/privacy-policy"}>{t("privacy_policy")}</Link>
                <Link to={"/return-policy"}>{t("return_policy")}</Link>
              </div>
            </div>
            <div className="newsletter">
              <a>{t("join_our_newsletter")}</a>
              <div className="inputContainer">
                <input
                  placeholder={t("enter_your_email")}
                  className="phoneInput"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <BsFillArrowRightCircleFill
                  className="inputButton"
                  color="#3B71FE"
                  size={32}
                  onClick={this.subscribe}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>Copyright © 2024 Doktap LLC. All rights reserved</p>
        </div>
      </div>
    );
  }
}
