import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import "../assets/styles/sass/Modal/OPTModal.sass";
import { ReactComponent as CloseIcon } from "../assets/static/close-modal.svg";
import "../assets/styles/sass/Modal/ForgetConfirmModal.sass";
import axiosData from "../helpers/axiosData";
import { toast } from "react-toastify";
import { t } from "i18next";

export default function ResetPasswordModal(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleModalClose = () => {
    props.setResetPasswordOpen(false);
  };

  const handleForgetPassword = () => {
    axiosData
      .post("/doctor/auth/reset-password/", {
        token: props.forgetData.token,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((res) => {
        handleModalClose();
        toast.success(
          "Updated Passsword Successfully. You can log in with new password"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBackLogin = () => {
    handleModalClose();
    props.setLoginModalOpen(true);
  };

  return (
    <div>
      <Modal
        open={props.resetPasswordOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="boxContainer">
          <div className="titleContainer">
            <h1 id="header-title" variant="h6" component="h2">
              {t('reset_your_password')}
            </h1>
            <p id="modal-modal-title">{t('please_set_new_password')}</p>
            <h3 id="modal-modal-title">
              <input
                type="password"
                placeholder={t('new_password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </h3>
            <h3 id="modal-modal-title">
              <input
                type="password"
                placeholder={t('confirm_new_password')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </h3>
            <div className="forgetButtons">
              <button className="continueButton" onClick={handleForgetPassword}>
                {t('continue')}
              </button>
              <button className="backLoginButton" onClick={handleBackLogin}>
                {t('back_to_login')}
              </button>
            </div>
            <div className="closeContainer" onClick={() => handleModalClose()}>
              <CloseIcon />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
