import React, { useState, useEffect, useContext } from "react";
import MainHeader from "../../assets/static/main_header.png";
import "../../assets/styles/sass/Dashboard/Dashboard.sass";
import axiosData from "../../helpers/axiosData";
import { useTranslation } from "react-i18next";
import LoginModal from "../../Modals/LoginModal";
import AuthContext from "../../context/AuthProvider";


export default function Index() {
  const { auth } = useContext(AuthContext);

  const { t } = useTranslation();
  const [headerData, setHeaderData] = useState([]);
  const [title, setTitle] = useState("");

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleModalOpen = () => {
    setShowLoginModal(true);
  };
  const getProfilePage = () => {
    let token = localStorage.getItem("token");
    let type = localStorage.getItem("type");
    if (type === "user") {
      window.open(process.env.REACT_APP_USER_URL + "?token=" + token, "_self");
    } else if (type === "doctor") {
      window.open(
        process.env.REACT_APP_DOCTOR_URL + "?token=" + token,
        "_self"
      );
    }
  };

  useEffect(() => {
    axiosData.get("header").then((res) => {
      setHeaderData(res.data.body);
      console.log(res)
      setTitle(
        res.data.body.title
          .replace(
            "%(doctor_count)s",
            `<span class="goldenText">${res.data.body.doctorCount}</span>`
          )
          .replace("%(category_count)s", res.data.body.categoryCount)
      );
    });
  }, []);

  return (
    <div>
      <div className="headerContainer">
        <img className="headerImage" src={MainHeader} alt="Main Header" />
        <div className="headerText">
          <p
            className="findText"
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
          <p className="bookText">{headerData.description}</p>
          <LoginModal open={showLoginModal} setOpen={setShowLoginModal} />
          {auth ? (
            <button className="searchButton" style={{"cursor":"pointer"}} onClick={() => getProfilePage()} >{t("start_search")}</button>

          ) : (
             <button className="searchButton" style={{"cursor":"pointer"}} onClick={() => handleModalOpen()} >{t("start_search")}</button>

          )}
        </div>
      </div>
    </div>
  );
}
