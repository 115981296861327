import React, { Component } from "react";
import Layout from "../components/layout/Layout";
import AboutUs from "../components/About/Index";

export default class About extends Component {
  render() {
    return (
      <div className="mainContainer">
        <Layout>
          <AboutUs />
        </Layout>
      </div>
    );
  }
}
