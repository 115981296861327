import React, { Component } from "react";
import "../../assets/styles/css/Service.css";
import { BiSearch } from "react-icons/bi";
import axiosData from "../../helpers/axiosData";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicesData: [],
    };
  }

  getServiceData = async () => {
    const servicesData = await axiosData.get("/amazing-service");
    this.setState({ servicesData: servicesData.data.body });
  };

  componentDidMount() {
    this.getServiceData();
  }

  render() {
    return (
      <div className="container">
        <h1 className="cardHeader">{this.state.servicesData.title}</h1>
        <div className="cardContainer">
          {this.state.servicesData.length !== 0 &&
            this.state.servicesData.services.map((service, index) => {
              return (
                <div className="card" key={index}>
                  <div className="cardBody">
                    <img src={service.icon} alt={`service${index}`} />
                    <p className="cardTitle">{service.title}</p>
                    <p className="cardDescription">{service.description}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
