/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect, useContext } from "react";
import "../../assets/styles/css/Header.css";
import { CSSTransition } from "react-transition-group";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
// import { ReactComponent as LogoWhite } from "../../assets/static/Logo_white.svg";
import { ReactComponent as Globe } from "../../assets/static/Shape.svg";
import { ReactComponent as GlobeWhite } from "../../assets/static/globe_white.svg";
import { ReactComponent as Logo } from "../../assets/static/logo.svg";
import { Link, useLocation } from "react-router-dom";
import i18n from "i18next";
import LoginModal from "../../Modals/LoginModal";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/AuthProvider";

export default function Header() {
  const { auth } = useContext(AuthContext);

  const { t } = useTranslation();

  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const location = useLocation();

  const handleModalOpen = () => {
    setShowLoginModal(true);
    setNavVisibility(false);
  };

  const getProfilePage = () => {
    let token = localStorage.getItem("token");
    let type = localStorage.getItem("type");
    if (type === "user") {
      window.open(process.env.REACT_APP_USER_URL + "?token=" + token, "_self");
    } else if (type === "doctor") {
      window.open(
        process.env.REACT_APP_DOCTOR_URL + "?token=" + token,
        "_self"
      );
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const onChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("language", event.target.value);
    window.location.reload();
  };

  return (
    <header
      className="Header"
      style={
        !isNavVisible ? { background: "white" } : { background: "#353945" }
      }
    >
      <div className="headerLeftContainer">
        {isNavVisible ? (
          <AiOutlineClose
            onClick={() => setNavVisibility(!isNavVisible)}
            color={"white"}
          />
        ) : isSmallScreen ? (
          <FiMenu onClick={() => setNavVisibility(!isNavVisible)} size={20} />
        ) : null}
        {!isSmallScreen || !isNavVisible ? (
          <Link to="/">
            <Logo style={{ scale: "1.4", margin: "0 20px" }} />
            <p>DOKTAP</p>
            {/* <img src={require('../../assets/static/Logo.png')} className="Logo"  style={{ marginLeft: "0" }} alt="Logo" /> */}
          </Link>
        ) : (
          // <LogoWhite />
          <>
            <Logo style={{ scale: "1.4", margin: "0 20px" }} />
            <p style={{ fontSize:"32px", margin: "0" ,fontFamily:"sans-serif",color:"white"}} >DOKTAP</p>
          </>
        )}
      </div>
      {!isNavVisible ? (
        <>
          {auth ? (
            <button
              className="headerRightButton"
              onClick={() => getProfilePage()}
            >
              {t("profile")}
            </button>
          ) : (
            <button
              className="headerRightButton"
              onClick={() => handleModalOpen()}
            >
              {t("sign_in")}
            </button>
          )}
        </>
      ) : null}
      <LoginModal open={showLoginModal} setOpen={setShowLoginModal} />
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      >
        <nav className="Nav">
          <Link
            to="/"
            className={location.pathname === "/" ? "selected" : null}
            style={!isSmallScreen ? { display: "none" } : { display: "block" }}
          >
            Main page
          </Link>
          <Link
            to="/about"
            className={location.pathname === "/about" ? "selected" : null}
          >
            {t("about_us")}
          </Link>
          <Link
            to="/support"
            className={location.pathname === "/support" ? "selected" : null}
          >
            {t("support")}
          </Link>
          <Link
            to="/contact"
            className={location.pathname === "/contact" ? "selected" : null}
          >
            {t("contact_us")}
          </Link>
          <div className="footerButtons">
            {isNavVisible ? (
              <GlobeWhite className="globus" />
            ) : (
              <Globe className="globus" />
            )}
            <select
              className="localization"
              onChange={onChange}
              defaultValue={i18n.language}
            >
              <option value="az">Azərbaycan</option>
              <option value="en">English</option>
              <option value="ru">Русский</option>
            </select>
          </div>
          {auth ? (
            <button onClick={() => getProfilePage()}>{t("profile")}</button>
          ) : (
            <button onClick={() => handleModalOpen()}>{t("sign_in")}</button>
          )}
        </nav>
      </CSSTransition>
    </header>
  );
}
