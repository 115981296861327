import React, { Component } from "react";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import "../../assets/styles/sass/Faq/Faq.sass";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpenBody: false };
  }

  switchBodyOpen() {
    this.setState({
      isOpenBody: !this.state.isOpenBody,
    });
  }

  render() {
    return (
      <div>
        <div className="faqComponent" onClick={() => this.switchBodyOpen()}>
          <div className="leftFaq">
            <p
              className="number"
              style={this.state.isOpenBody ? { color: "#3B71FE" } : null}
            >
              {this.props.number}
            </p>
            <p className="title">{this.props.faqData.title}</p>
          </div>
          {this.state.isOpenBody ? (
            <FiChevronUp color="#777E91" className="chevronIcon" />
          ) : (
            <FiChevronDown color="#777E91" className="chevronIcon" />
          )}
        </div>
        {this.state.isOpenBody ? (
          <p className="faqBody">{this.props.faqData.description}</p>
        ) : null}
      </div>
    );
  }
}
