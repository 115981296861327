import About from "./pages/About";
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import Main from "./pages/Main";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import { initReactI18next } from "react-i18next";
import azerbaijani from './localization/azerbaijani.json'
import russian from './localization/russian.json'
import english from './localization/english.json'
import i18n from "i18next";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAgreement from "./pages/UserAgreement";
import ReturnPolicy from "./pages/ReturnPolicy";
import CookiePolicy from "./pages/CookiePolicy";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: english },
    ru: { translation: russian },
    az: { translation: azerbaijani },
  },
  lng: localStorage.getItem('language') || "az",
  fallbackLng: localStorage.getItem('language') || "az",
  interpolation: { escapeValue: false } 
})


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/support" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/user-agreement" element={<UserAgreement />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    </Router>
  );
}
  
export default App;
